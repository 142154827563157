import React, { useContext, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import {
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { Link } from "react-router-dom";
import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import Swal from "sweetalert2";

export const columns = [
  {
    dataField: "beni_name",
    text: "Name",
  },
  {
    dataField: "purpose",
    text: "Purpose",
  },
  {
    dataField: "beni_account",
    text: "Account",
  },
  {
    dataField: "beni_ifsc",
    text: "IFSC",
  },
  {
    dataField: "wl_before_balance",
    text: "Before Balance",
  },
  {
    dataField: "pay_amount",
    text: "Pay Amount",
  },
  {
    dataField: "wl_after_balance",
    text: "After Balance",
  },
  {
    dataField: "wl_indate",
    text: "Date",
  },
];

const Payouthistory = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    getEmployee();
  }, []);

  const getEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          empid: "0",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetPayoutHistory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const showDetails = (pk_order_id) => {
    const employee = employees.find((emp) => emp.pk_order_id === pk_order_id);
    if (employee) {
      Swal.fire({
        title: "Transaction Details",
        html: `
          <p><strong>Bank Response Code:</strong> ${employee.bank_responseCode}</p>
          <p><strong>Bank Response Message:</strong> ${employee.bank_responseMsg}</p>
          <p><strong>Bank Status:</strong> ${employee.bank_status}</p>
        `,
        icon: "info",
        confirmButtonText: "Close",
      });
    }
  };

  const filteredEmployees = employees.filter((item) =>
    Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(indexOfFirstItem, indexOfLastItem);


  const options = [
    { label: "50", value: 50 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
    { label: "2000", value: 2000 },
  ];

  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <div className="col-9">
                <h5
                  className="d-flex ml-md-3 align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Transaction History
                </h5>
              </div>

              <div className="col d-flex justify-content-end">
                <div className="px-2">
                  <input
                    style={{
                      background: "#fff",
                      borderRadius: ".4rem",
                      height: "2.4rem",
                      border: "1px #ced4da solid",
                    }}
                    type="text"
                    className="form-control form-control-sm px-2"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <Link to="/retailer/Payrollprocesssalary">
                  <Button className="btn-sm">Back</Button>
                </Link>
              </div>
            </div>

            <hr className="p-0 mx-md-1" />
            <table
              className="my-table mx-2 transparent-table text-center border-bottom"
              style={{
                width: "100%",
                overflowX: "auto",
                border: "0px",
                overflow: "auto",
              }}
            >
              <thead className="mx-md-2">
                <tr
                  className="borderless-table text-center border-bottom"
                  style={{
                    background: "white",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {"Sr"}
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Ref.Id
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Beni.Name
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Purpose
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Bank
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Transaction Type
                  </th>

                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Date
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Bank msg.
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Check Bank Status
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No data found
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item, index) => (
                    <tr className="border-bottom" key={index}>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.ref_id}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.benificiary_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.purpose}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.bank_ifsc} <br /> {item.bank_acc}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {twoDecimalPlaces(item.amount)}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.transfer_type}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {new Date(item.date).toLocaleDateString()}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.api_msg}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        <PiDotsThreeCircleLight
                          style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          onClick={() => showDetails(item.pk_order_id)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Pagination Dropdown */}
            <div className="m-3 d-flex justify-content-start">
              <Select
                options={options}
                defaultValue={options.find((opt) => opt.value === itemsPerPage)}
                onChange={(opt) => {
                  setItemsPerPage(opt.value);
                  setCurrentPage(1); 
                }}
                className="mr-2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payouthistory;

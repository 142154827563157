import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Row, Col, Table, Modal } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import { GlobalContext } from "../../Context/GlobalState";
import { ViewvendorbillTableAdmin } from "./SalaryTable";
import { billpunchbasicauth } from "../../utils/Credentials";
const arr = [{ label: "Name", value: "Name" }];
const Viewvendorbill = () => {
  const query = useQuery();
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [vendorData, setVendorData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password, username } = billpunchbasicauth;
  const GetVendorItems = async (vendorid, projectid) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        projectid: projectid || "0",
        vendorid: vendorid || "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetVendorItems`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const GetVendorDetails = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ firmid: user.firm_id });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetVendorDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const vendorOptions = data.data.map((vendor) => ({
          value: vendor.id,
          label: vendor.vendor_name,
          details: vendor,
        }));
        setVendorData(vendorOptions);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const getProjects = async () => {
    console.log("getProjects called");
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      console.log("API response data:", data);
      if (data.status === "0") {
        setProjects(data.data);
        console.log("Project data set:", data.data);
      } else {
        console.error("Failed to fetch projects:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption.value);
    setSelectedVendorDetails(selectedOption.details);
    // GetVendorItems(selectedOption.value);
  };
  useEffect(() => {
    GetVendorItems();
    getProjects();
    GetVendorDetails();
  }, []);
  const projectOptions = projects.map((project) => ({
    value: project.pk_project_id,
    label: project.project_name,
  }));

  const handleSelectprojectChange = (selectedOption) => {
    const selectedProjectId = selectedOption.value;
    setSelectedProject(selectedProjectId);
    // GetVendorItems(selectedVendor, selectedProjectId);
    console.log("Selected Project ID:", selectedProjectId);
  };
  return (
    <>
      <div>
        <div className=" row mt-md-3 mt-sm-2 p-0 ">
          <h4 className="col mx-md-3 mx-sm-2 mb-sm-4 d-flex align-items-center ">
            {" "}
            {user.organisation} Vendor Bills
          </h4>

          <div className=" col mr-md-4 mr-sm-3 d-flex justify-content-end">
          <div style={{ marginRight: ".5rem", marginRight: ".5rem" }}>
                  <Link to="/retailer/Add_Vendor_bill">
                    <Button
                      size="sm"
                      variant="success"
                      style={{ height: "2.2rem" }}
                    >
                      Add Vendor Bill
                    </Button>
                  </Link>
                </div>
            <Link to={"/retailer/ExpenseDashboardAdmin"}>
              <button
                type="button"
                className="btn btn-sm justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2.5em",
                  // width: "8rem",
                  border: "None",
                  color: "#fff",
                }}
              >
                Back
              </button>
            </Link>
          </div>
        </div>
        <Row className="mx-md-1 p-0">
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label> Select Vendor</Form.Label>
              <Select
                options={vendorData}
                value={vendorData.find(
                  (option) => option.value === selectedVendor
                )}
                onChange={handleVendorChange}
                placeholder="Select Vendor"
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Project</Form.Label>
              <Select
                options={projectOptions}
                onChange={handleSelectprojectChange}
                placeholder="Select a project"
              />
            </Form.Group>
          </Col>
          <Col md={4} className="d-flex align-items-center mt-2">
            <Button
              onClick={() => GetVendorItems(selectedVendor, selectedProject)}
              style={{
                backgroundColor: "#2ca01c",
                border: "none",
                borderRadius: ".4em",
              }}
            >
              Fetch Bills
            </Button>
          </Col>
        </Row>
        <div
          className=" p-0 mx-md-3"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <ViewvendorbillTableAdmin
            data={data}
            GetVendorItems={GetVendorItems}
          />
        </div>
      </div>
    </>
  );
};

export default Viewvendorbill;

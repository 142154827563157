import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import swal from "sweetalert";
const Vendoritems = () => {
  const selectedVendor = JSON.parse(sessionStorage.getItem("selectedvendor"));
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [projects, setProjects] = useState([]);
  const [items, setItems] = useState([
    {
      item: "",
      item_desc: "",
      qty: "",
      perValue: "",
      total: "",
      bill_url: "",
      projectid: "",
    },
  ]);

  const getProjects = async () => {
    console.log("getProjects called");
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      console.log("API response data:", data);
      if (data.status === "0") {
        setProjects(data.data);
        console.log("Project data set:", data.data);
      } else {
        console.error("Failed to fetch projects:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const addRow = () => {
    setItems([
      ...items,
      {
        item: "",
        item_desc: "",
        qty: "",
        perValue: "",
        total: "",
        bill_url: "",
        projectid: "",
      },
    ]);
  };

  const removeRow = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleInputChange = (index, key, value) => {
    const updatedItems = [...items];
    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  const handleSubmit = async () => {
    const requestBody = {
      requests: items.map((item) => ({
        ...item,
        firmid: user.firm_id,
        vendorid: selectedVendor.id,
      })),
    };
  
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddVendorItems`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );
      const response = await res.json();
      console.log("API Response:", response);
  
      if (response.status === "0" && response.result[0].result === "Y") {
        swal("Success", response.result[0].message, "success");
      } else {
        swal("Error", "Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      console.log("Error submitting data:", error);
      swal("Error", "An error occurred while submitting. Please try again.", "error");
    }
  };

  return (
    <>
      <div className="row mt-2">
        <h4 className="col">Vendor Management</h4>
        <div className="col d-flex justify-content-end">
        <Link to="/retailer/Add_Vendor_bill">
            <Button size="sm" variant="success">
             Add Bill
            </Button>
          </Link>
          <div style={{marginLeft:".5rem"}}>
          <Link to="/retailer/Vendor_Management">
            <Button size="sm" variant="info">
              Back
            </Button>
          </Link>
          </div>
        </div>
      </div>
    
      <Table striped bordered hover className="m-2">
        <thead>
          <tr>
            <th>Project</th>
            <th>Item</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Per Value</th>
            <th>Total</th>
            <th>Bill URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "200px" }}>
                {projects && projects?.length > 0 ? (
                  <Select
                    value={
                      item?.projectid
                        ? {
                            value: item?.projectid,
                            label: projects?.find(
                              (project) =>
                                project.pk_project_id === item?.projectid
                            )?.project_name,
                          }
                        : { value: "NA", label: "Select Project" }
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        index,
                        "projectid",
                        selectedOption?.value
                      )
                    }
                    options={[
                      { value: "NA", label: "Select Project" },
                      ...projects?.map((project) => ({
                        value: project?.pk_project_id,
                        label: project?.project_name,
                      })),
                    ]}
                    placeholder="Select Project"
                  />
                ) : (
                  <span>Loading projects...</span>
                )}
              </td>

              <td>
                <Form.Control
                  value={item?.item}
                  onChange={(e) =>
                    handleInputChange(index, "item", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={item?.item_desc}
                  onChange={(e) =>
                    handleInputChange(index, "item_desc", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={item?.qty}
                  onChange={(e) =>
                    handleInputChange(index, "qty", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={item?.perValue}
                  onChange={(e) =>
                    handleInputChange(index, "perValue", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={item?.total}
                  onChange={(e) =>
                    handleInputChange(index, "total", e.target.value)
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={item?.bill_url}
                  onChange={(e) =>
                    handleInputChange(index, "bill_url", e.target.value)
                  }
                />
              </td>

              <td>
                <Button variant="danger" onClick={() => removeRow(index)}>
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      &nbsp; <Button variant="primary" size="sm" onClick={addRow}>
        Add Row
      </Button> &nbsp;
      <Button variant="success" size="sm" className="ml-2" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export default Vendoritems;

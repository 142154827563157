import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Employeemanagment = () => {
  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "11");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);


  return (
    <>
         <Helmet>
  <title>{pageData ? pageData.basic_settings.tittle : "Loading..."}</title>
  <meta name="description" content={pageData ? pageData.basic_settings.meta_desc : "Loading..."} />
  <meta name="keywords" content={pageData ? pageData.keyword_settings.map(keyword => keyword.keyword).join(", ") : "Loading..."} />
  <link rel="canonical" href={pageData ? pageData.basic_settings.canonical_tag : "#"} />
  <meta property="og:title" content={pageData ? pageData.og_settings?.og_title || "Loading..." : "Loading..."} />
  <meta property="og:description" content={pageData ? pageData.og_settings?.og_desc || "Loading..." : "Loading..."} />
  <meta property="og:image" content={pageData ? pageData.og_settings?.og_image || "#" : "#"} />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
  {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}
</Helmet>
      <Navbar />
      
      <h1 className="text-center mt-3">Employee Management</h1>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
          <p>
            BillPunch offers a comprehensive solution for managing employee information, ensuring seamless integration and efficient HR operations. Our Employee Management module is designed to simplify the complexities of human resource tasks and enhance the employee experience, making it easier for organizations to manage their workforce effectively.
          </p>
          <h5>1. Centralized Employee Database:</h5>
          <ul>
            <li>Maintain a single, centralized repository of all employee information.</li>
            <li>Store personal details, job profiles, contact information, emergency contacts, and more.</li>
            <li>Easy access and retrieval of employee data with advanced search functionality.</li>
          </ul>
          <h5>2. Employee Onboarding:</h5>
          <ul>
            <li>Streamlined onboarding process with automated workflows.</li>
            <li>Digital documentation and e-signature capabilities for a paperless experience.</li>
            <li>Onboarding checklists to ensure new hires complete all necessary steps.</li>
          </ul>
          <h5>3. Role and Permissions Management:</h5>
          <ul>
            <li>Define roles and assign permissions to control access to sensitive information.</li>
            <li>Ensure compliance with data privacy regulations by restricting data visibility.</li>
            <li>Customize access levels based on organizational hierarchy and job functions.</li>
          </ul>
          <h5>4. Attendance Tracking:</h5>
          <ul>
            <li>Monitor employee attendance with real-time tracking.</li>
            <li>Integrate with biometric devices or mobile apps for accurate timekeeping.</li>
            <li>Generate reports on attendance patterns, absenteeism, and punctuality.</li>
          </ul>
          <h5>5. Leave Management:</h5>
          <ul>
            <li>Simplify leave requests and approvals with an automated system.</li>
            <li>Track various leave types including sick leave, vacation, and maternity leave.</li>
            <li>Maintain accurate leave balances and generate leave reports.</li>
          </ul>
          <h5>6. Performance Management:</h5>
          <ul>
            <li>Set and track individual and team performance goals.</li>
            <li>Conduct performance appraisals and provide 360-degree feedback.</li>
            <li>Identify top performers and areas needing improvement through detailed analytics.</li>
          </ul>
          <h5>7. Payroll Integration:</h5>
          <ul>
            <li>Seamlessly integrate with payroll systems for accurate salary computations.</li>
            <li>Manage employee compensation, bonuses, deductions, and tax calculations.</li>
            <li>Ensure timely and error-free payroll processing.</li>
          </ul>
          <h5>8. Employee Self-Service Portal:</h5>
          <ul>
            <li>Empower employees with self-service capabilities for updating personal information.</li>
            <li>Enable employees to view payslips, apply for leave, and access company policies.</li>
            <li>Enhance communication with announcements and bulletin boards.</li>
          </ul>
          <h5>9. Training and Development:</h5>
          <ul>
            <li>Track employee training programs and certifications.</li>
            <li>Identify skill gaps and plan career development activities.</li>
            <li>Ensure compliance with mandatory training requirements.</li>
          </ul>
          <h5>10. Analytics and Reporting:</h5>
          <ul>
            <li>Generate comprehensive reports on various HR metrics.</li>
            <li>Use data-driven insights to inform strategic HR decisions.</li>
            <li>Customizable dashboards to monitor key performance indicators (KPIs).</li>
          </ul>
          <h5>Why is it important?</h5>
          <ul>
            <li><strong>Improved Efficiency:</strong> Automate routine HR tasks to reduce administrative burden and increase productivity.</li>
            <li><strong>Enhanced Accuracy:</strong> Minimize errors in employee data management, payroll processing, and compliance reporting.</li>
            <li><strong>Better Employee Experience:</strong> Provide a user-friendly interface and self-service options to improve employee satisfaction.</li>
            <li><strong>Informed Decision-Making:</strong> Leverage data analytics to make informed decisions about workforce management and planning.</li>
            <li><strong>Scalability:</strong> Easily scalable to accommodate growing organizations and evolving HR needs.</li>
          </ul>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer />
    </>
  );
};

export default Employeemanagment;

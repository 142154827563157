import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { CiCircleRemove, CiImageOn } from "react-icons/ci";
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckCircle,
  FaCode,
  FaExclamationCircle,
  FaHourglassHalf,
  FaSearch,
  FaTasks,
} from "react-icons/fa";
import {
  Badge,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Table,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdMessage } from "react-icons/md";
import { FcParallelTasks } from "react-icons/fc";
import swal from "sweetalert";
import { IoIosAttach, IoIosSend } from "react-icons/io";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Link } from "react-router-dom";
const Taskoverview = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [project, setproject] = useState([]);
  const [employee, setemployee] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [platform, setplatform] = useState([]);
  const [platformfilter, setplatformfilter] = useState(null);
  const [taskdata, setTaskData] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarOpen1, setIsSidebarOpen1] = useState(false);
  const [isSidebarOpen2, setIsSidebarOpen2] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sendActivity, setSendActivity] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImageUrl, setOverlayImageUrl] = useState(null);
  const [msg, setmsg] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showAllActivities, setShowAllActivities] = useState(false);
  const [activity, setActivity] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [highlightedCode, setHighlightedCode] = useState("");
  const textareaRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [recentTaskData, setRecentTaskData] = useState([]);
  const [displayCount, setDisplayCount] = useState(5);
  const [searchrecenttask, setSearchrecenttask] = useState('');
  const filteredTasks = recentTaskData.filter((task) =>
    task.taskname.toLowerCase().includes(searchrecenttask.toLowerCase()) ||
    task.project_name.toLowerCase().includes(searchrecenttask.toLowerCase()) ||
    task.category.toLowerCase().includes(searchrecenttask.toLowerCase())
  );
  
  const handleShowMore = () => {
    setDisplayCount(recentTaskData.length);
  };
  const handleShowLess = () => {
    setDisplayCount(5);
  };
  const statusOptions = [
    { value: "", label: "All" },
    { value: "C", label: "Complete" },
    { value: "P", label: "Pending" },
    { value: "O", label: "Overdue" },
  ];

  const employeeOptions = [
    { value: "clear", label: "Clear" },
    ...employee.map((emp) => ({
      value: emp.empid,
      label: `${emp.firstname} ${emp.lastname}`,
    })),
  ];
  const projectOptions = [
    { value: "clear", label: "Clear" },
    ...project.map((proj) => ({
      value: proj.pk_project_id,
      label: proj.project_name,
    })),
  ];
  const categoryOptions = [{ value: "clear", label: "Clear" }, ...categories];
  const platformOptions = [{ value: "clear", label: "Clear" }, ...platform];
  const handleStatusChange = (selectedOption) => {
    if (selectedOption?.value === "") {
      setSelectedStatus(null);
      getTask();
    } else {
      setSelectedStatus(selectedOption);
    }
  };
  const handleImageClick = (imageUrl) => {
    setOverlayImageUrl(imageUrl);
    setShowOverlay(true);
  };
  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayImageUrl(null);
  };
  const handleChange = (selectedOption) => {
    if (selectedOption?.value === "clear") {
      setSelectedProject(null);
      getTask();
    } else {
      setSelectedProject(selectedOption);
    }
  };
  const handleEmployeeChange = (selectedOption) => {
    if (selectedOption?.value === "clear") {
      setSelectedEmployee(null);
      getTask();
    } else {
      setSelectedEmployee(selectedOption);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleFilterChange = () => {
    if (startDate && endDate) {
      getTask(startDate, endDate);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    if (selectedOption?.value === "clear") {
      setCategoryFilter(null);
      getTask();
    } else {
      setCategoryFilter(selectedOption);
    }
  };
  const handleplatformChange = (selectedOption) => {
    if (selectedOption?.value === "clear") {
      setplatformfilter(null);
      getTask();
    } else {
      setplatformfilter(selectedOption);
    }
  };
  const handleTaskClick = (task) => {
    setSelectedTask(task);
    // setIsSidebarOpen(true);
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleTaskClick1 = (task) => {
    setSelectedTask(task);
    setIsSidebarOpen1(true);
    getActivity();
  };
  const handleTaskClick2 = async (task) => {
    setSelectedTask(task);
    setIsSidebarOpen2((prevState) => {
      if (!prevState) {
        getrecentTask();
      }
      return !prevState;
    });
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedTask(null);
  };

  const handleCodeChange = (event) => {
    setUserCode(event.target.value);
  };
  const handleSearch1Change = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    setUserCode(pastedData);
  };
  const handleCloseSidebar1 = () => {
    setIsSidebarOpen1(false);
    setSelectedTask(null);
    setActivity([]);
    setSendActivity("");
    setFileName("");
    setSelectedFile(null);
    setUserCode("");
  };
  useEffect(() => {
    if (isSidebarOpen1 && selectedTask) {
      getActivity();
    }
  }, [isSidebarOpen1, selectedTask]);
  useEffect(() => {
    if (isSidebarOpen2 && selectedTask) {
      getrecentTask();
    }
  }, [isSidebarOpen1, selectedTask]);

  const handleCloseSidebar2 = () => {
    setIsSidebarOpen2(false);
    setSelectedTask(null);
  };
  const hasValidSubtasks = (subtasks) => {
    return (
      subtasks &&
      subtasks.some((subtask) => subtask.sub_name && subtask.sub_empname)
    );
  };
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleDateString();
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const getStatusStyle = (status) => {
    switch (status) {
      case "C":
        return { color: "#387F39" };
      case "P":
        return { color: "#FFB22C" };
      case "O":
        return { color: "#FF0000" };
      default:
        return { color: "black" };
    }
  };
  const formatDescription = (description) => {
    return description
      .split("\n")
      .map((point, index) => <li key={index}>{point}</li>);
  };
  const getTask = async (fromdate, todate) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        fromdate,
        todate,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetTaskSubtaskOverview`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getemployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setemployee(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setproject(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const fetchCategories = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({  firmid:user.firm_id, }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedCategories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategories(fetchedCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchPlatforms = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ taskid: "string" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedplatform = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setplatform(fetchedplatform);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const getrecentTask = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: selectedTask.task_empid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetRecentTaskEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setRecentTaskData(data.data); // Set the API response data
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const filteredTaskData = taskdata.filter((task) => {
    console.log("Checking task:", task);
    console.log("Formatted start date:", formatDate(task.task_startdate));
    console.log("Formatted end date:", formatDate(task.task_enddate));

    const lowerCaseQuery = searchQuery.toLowerCase();
    const matchesQuery =
      task.project_name.toLowerCase().includes(lowerCaseQuery) ||
      task.taskname.toLowerCase().includes(lowerCaseQuery) ||
      task.task_description.toLowerCase().includes(lowerCaseQuery) ||
      task.task_assignee.toLowerCase().includes(lowerCaseQuery) ||
      formatDate(task.task_startdate).toLowerCase().includes(lowerCaseQuery) ||
      formatDate(task.task_enddate).toLowerCase().includes(lowerCaseQuery) ||
      task.task_status.toLowerCase().includes(lowerCaseQuery) ||
      task.category.toLowerCase().includes(lowerCaseQuery);

    const matchesProject = selectedProject
      ? task.project_name === selectedProject.label
      : true;
    const matchesEmployee = selectedEmployee
      ? task.task_assignee === selectedEmployee.label
      : true;

    const matchesCategory = categoryFilter
      ? task.category === categoryFilter.label
      : true;
    const matchesPlatform = platformfilter
      ? task.platform === platformfilter.label
      : true;
    const matchesStatus = selectedStatus
      ? task.task_status === selectedStatus.value
      : true;

    return (
      matchesQuery &&
      matchesProject &&
      matchesEmployee &&
      matchesCategory &&
      matchesPlatform &&
      matchesStatus
    );
  });

  const fileInputRef = useRef(null);

  const handleFileSelection = () => {
    fileInputRef.current.click();
  };

  const toggleCodeModal = () => {
    setShowCodeModal(!showCodeModal);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName("");
    }
  };

  const popover = (
    <Popover style={{ background: "#fff" }} id="attachment-popover">
      <Popover.Content style={{ background: "#fff" }}>
        <CiImageOn
          onClick={handleFileSelection}
          className="mx-2"
          style={{ fontSize: "1.4rem", cursor: "pointer" }}
        />

        <FaCode onClick={toggleCodeModal} style={{ fontSize: "1.4rem" }} />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Popover.Content>
    </Popover>
  );

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const getActivity = async () => {
    setTransactionLoaderState(true);
    setActivity([]);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        task_id: selectedTask.pk_taskid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetRecentActivitiesSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setActivity(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let byteArray = "";
    if (selectedFile) {
      byteArray = await convertToByteArray(selectedFile);
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        taskid: selectedTask.pk_taskid,
        desc: sendActivity,
        code: userCode,
        image_byte: byteArray,
        image_name: selectedFile ? selectedFile.name : "",
        image_type: selectedFile ? selectedFile.type : "jpeg",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskActivities`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        getActivity();
        setSendActivity("");
        setSelectedFile(null);
        setFileName("");
        setUserCode("");
      } else {
        swal("Success", data.msg, "success");
        getActivity();
        setSendActivity("");
        setSelectedFile(null);
        setFileName("");
        setUserCode("");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const filteredActivity = activity.filter((item) => {
    return (
      item?.namee?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.codee?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.rec_desc?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.taskname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.datee?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  useEffect(() => {
    const today = getCurrentDate();
    getTask(today, today);
  }, []);
  useEffect(() => {
    getProjects();
    getemployee();
    fetchCategories();
    fetchPlatforms();
    getrecentTask();
  }, []);
  useEffect(() => {
    if (userCode) {
      setHighlightedCode(
        <SyntaxHighlighter language="jsx" style={vs}>
          {userCode}
        </SyntaxHighlighter>
      );
    }
  }, [userCode]);
  useEffect(() => {
    getActivity();
  }, []);
  const { accessDataemp } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessDataemp);
  }, [accessDataemp]);

  return (
    <div className="task-overview-container  p-3">
      <div className="row ">
        <h4 className="col ">Task Overview</h4>
        <div className="col-4 d-flex  justify-content-end align-items-center">
          <div style={{ marginRight: ".5rem" }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </div>

          <button onClick={handleFilterChange} className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <div className="row ">
        <div className="col d-flex  align-items-center">
          <input
            type="text"
            placeholder="Search tasks..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="form-control"
            style={{ width: "15%", marginRight: ".5rem" }}
          />
          <div style={{ marginRight: ".5rem" }}>
            <Select
              value={selectedEmployee}
              onChange={handleEmployeeChange}
              options={employeeOptions}
              placeholder="Search Employee"
            />
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <Select
              value={selectedProject}
              onChange={handleChange}
              options={projectOptions}
              placeholder="Search Project"
            />
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <Select
              className="px-1"
              placeholder="Search Category"
              options={categoryOptions}
              value={categoryFilter}
              onChange={handleCategoryChange}
            />
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <Select
              className="px-1"
              placeholder="Search Platform"
              options={platformOptions}
              value={platformfilter}
              onChange={handleplatformChange}
            />
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              options={statusOptions}
              placeholder="Select Status"
            />
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>Project</th>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>
              Description
            </th>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>
              Assignee
            </th>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>Date</th>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>Status</th>
            <th style={{ background: "#DEF9C4", color: "#03346E" }}>
              Category
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTaskData.length > 0 ? (
            filteredTaskData.map((task) => (
              <tr key={task.pk_taskid}>
                <td>{task.project_name}</td>
                <td>
                  <b>{task.taskname}</b> ({task.platform})
                  {hasValidSubtasks(task.subtask) && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">Check Subtask</Tooltip>
                      }
                    >
                      <FcParallelTasks
                        style={{
                          fontSize: "1.5rem",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleTaskClick(task)}
                      />
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Message here</Tooltip>}
                  >
                    <MdMessage
                      style={{
                        color: "#007bff",
                        marginLeft: "8px",
                        fontSize: "1.3rem",
                        marginRight: "8px",
                      }}
                      onClick={() => handleTaskClick1(task)}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Check Recent
                        <br /> Activities
                      </Tooltip>
                    }
                  >
                    <FaTasks
                      style={{ color: "#007bff", fontSize: "1.2rem" }}
                      onClick={() => handleTaskClick2(task)}
                    />
                  </OverlayTrigger>
                  <br />
                  <ul>{formatDescription(task.task_description)}</ul>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={task.image_url}
                      alt="Profile"
                      style={{
                        width: "2rem",
                        height: "2rem",
                        borderRadius: "50%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(task.image_url)}
                    />
                    <span style={{ marginTop: "0.5rem", textAlign: "center" }}>
                      {task.task_assignee}
                    </span>
                  </div>
                </td>

                <td>
                  {formatDate(task.task_startdate)} to <br />
                  {formatDate(task.task_enddate)}
                </td>
                <td style={getStatusStyle(task.task_status)}>
                  {task.task_status === "C"
                    ? "Complete"
                    : task.task_status === "P"
                    ? "Pending"
                    : task.task_status === "O"
                    ? "Overdue"
                    : "Unknown"}
                </td>
                <td>{task.category}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {showOverlay && overlayImageUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={overlayImageUrl}
            alt="Profile Overlay"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
            }}
          />
        </div>
      )}
      {isSidebarOpen && selectedTask && (
        <div className="task-sidebar">
          <div className="task-sidebar-content">
            <div className="row">
              <h5 className="col">Subtasks for {selectedTask.taskname}</h5>
              <div className="col-2 d-flex align-items-center">
                <CiCircleRemove
                  style={{ fontSize: "2rem" }}
                  onClick={handleCloseSidebar}
                />
              </div>
            </div>
            <div className="subtask-cards">
              {hasValidSubtasks(selectedTask.subtask) ? (
                selectedTask.subtask.map((subtask) => (
                  <div className="card p-2 mb-3" key={subtask.pk_subtaskid}>
                    <div className="card-body p-3">
                      <h5 className="card-title" style={{ color: "black" }}>
                        {subtask.sub_name}
                      </h5>
                      <p className="card-text">
                        <strong>Assignee:</strong> {subtask.sub_empname}
                      </p>
                      <p className="card-text">
                        <strong>Description:</strong> {subtask.subtask_desc}
                      </p>
                      <p className="card-text">
                        <strong>Start Date:</strong>{" "}
                        {formatDate(subtask.sub_startdate)}
                      </p>
                      <p className="card-text">
                        <strong>End Date:</strong>{" "}
                        {formatDate(subtask.sub_enddate)}
                      </p>
                      <p className="card-text">
                        <strong>Priority:</strong> {subtask.priority}
                      </p>
                      <p className="card-text">
                        <strong>Status:</strong>{" "}
                        <span
                          style={
                            subtask.subtask_status === "C"
                              ? { color: "#387F39" }
                              : subtask.subtask_status === "P"
                              ? { color: "#FFB22C" }
                              : subtask.subtask_status === "O"
                              ? { color: "#FF0000" }
                              : { color: "black" }
                          }
                        >
                          {subtask.subtask_status === "C"
                            ? "Complete"
                            : subtask.subtask_status === "P"
                            ? "Pending"
                            : subtask.subtask_status === "O"
                            ? "Overdue"
                            : "Unknown"}
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No subtasks available.</p>
              )}
            </div>
          </div>
        </div>
      )}
      {isSidebarOpen1 && selectedTask && (
        <div className="task-sidebar">
          <div className="task-sidebar-content">
            <div className="row">
              <h5 className="col">Message for {selectedTask.taskname}</h5>
              <div className="col-2 d-flex align-items-center">
                <CiCircleRemove
                  style={{ fontSize: "2rem" }}
                  onClick={handleCloseSidebar1}
                />
              </div>
            </div>
            <div className="p-3">
              <div className="col d-flex justify-content-end align-items-center">
                {isSearchOpen ? (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch1Change}
                    autoFocus
                    onBlur={() => setIsSearchOpen(false)}
                  />
                ) : (
                  <FaSearch
                    onClick={toggleSearch}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <hr className="mt-0" />
              <div
                className="custom-scrollbaractivity"
                style={{
                  height: "50vh",
                  overflow: "auto",
                  background: "aliceblue",
                }}
              >
                {filteredActivity.length === 0 ? (
                  <p className="text-center p-3">No Messages Found</p>
                ) : (
                  <>
                    {filteredActivity.length > 2 && (
                      <Badge
                        onClick={() => setShowAllActivities(!showAllActivities)}
                      >
                        {showAllActivities ? <FaCaretUp /> : <FaCaretDown />}
                        &nbsp;{showAllActivities ? "Show Less" : "Show More"}
                      </Badge>
                    )}
                    {filteredActivity
                      .slice(0, showAllActivities ? filteredActivity.length : 7)
                      .map((item, index) => (
                        <div key={index} className="p-2">
                          &#8226; {item.namee}{" "}
                          {item.codee && (
                            <SyntaxHighlighter language="jsx" style={dracula}>
                              {item.codee}
                            </SyntaxHighlighter>
                          )}{" "}
                          <b>{item.rec_desc}</b> in {item.taskname} at{" "}
                          <b>{item.datee}</b>
                        </div>
                      ))}
                  </>
                )}
              </div>

              <Form style={{ background: "aliceblue" }} className="p-4">
                <Form.Group
                  controlId="sendActivityField"
                  style={{ position: "relative" }}
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={popover}
                    rootClose
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <IoIosAttach
                        style={{ color: "#0096FF", cursor: "pointer" }}
                      />
                    </div>
                  </OverlayTrigger>
                  {fileName && (
                    <div style={{ margin: "10px 0" }}>
                      <strong>Attached File:</strong> {fileName}
                    </div>
                  )}
                  <IoIosSend
                    style={{
                      color: "#0096FF",
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                    onClick={handleSubmit}
                  />
                  {accessDataemp &&
                    accessDataemp.map(
                      (module) =>
                        module.data &&
                        module.data.map((role) =>
                          role.role_name === "Add Activities" ? (
                            role.access === "Y" ? (
                              <span key={role.pk_role_id}>
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  placeholder="Write a Comment ..."
                                  value={sendActivity}
                                  onChange={(e) =>
                                    setSendActivity(e.target.value)
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      handleSubmit(e);
                                    }
                                  }}
                                  style={{
                                    paddingLeft: "30px",
                                    paddingRight: "40px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    background: "#fff",
                                  }}
                                />
                              </span>
                            ) : (
                              <span key={role.pk_role_id}>
                                <Form.Control
                                  as="textarea"
                                  placeholder="Write a Comment ..."
                                  disabled
                                  style={{
                                    paddingLeft: "30px",
                                    paddingRight: "40px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    background: "#fff",
                                  }}
                                />
                              </span>
                            )
                          ) : null
                        )
                    )}
                </Form.Group>
              </Form>
              <Modal show={showCodeModal} onHide={toggleCodeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Code Example</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    value={userCode}
                    onChange={handleCodeChange}
                    onPaste={handlePaste}
                    placeholder="Paste your code here..."
                    ref={textareaRef}
                  />
                  {highlightedCode}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggleCodeModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSubmit}>
                    Upload Code
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      )}
      {isSidebarOpen2 && selectedTask && (
        <div className="task-sidebar">
          <div className="task-sidebar-content mb-5 pb-5">
            <div className="row pb-0">
              <h5 className="col">
                Recent Activities for {selectedTask.task_assignee}
              </h5>
              <div className="col-2 d-flex align-items-center">
                <CiCircleRemove
                  style={{ fontSize: "2rem" }}
                  onClick={handleCloseSidebar2}
                />
              </div>
            </div>
            <div className="subtask-cards mt-0">
              <div className="row">
                <div className="col">
                  {recentTaskData.length > 5 && (
                    <div className="show-more-less">
                      {displayCount < recentTaskData.length ? (
                        <Badge
                          className="badge badge-pill badge-info"
                          onClick={handleShowMore}
                        >
                          Show More
                        </Badge>
                      ) : (
                        <Badge
                          className="badge badge-pill badge-warning"
                          onClick={handleShowLess}
                        >
                          Show Less
                        </Badge>
                      )}
                    </div>
                  )}
                </div>
                <div className="col">
                <input
              type="text"
              className="form-control"
              placeholder="Search tasks..."
              value={searchrecenttask}
              style={{height:"2rem"}}
              onChange={(e) => setSearchrecenttask(e.target.value)}
            />
                </div>
              </div>
              {filteredTasks.length > 0 ? (
                filteredTasks
                  .sort(
                    (a, b) =>
                      new Date(b.task_startdate) - new Date(a.task_startdate)
                  ) // Sort by task_startdate in descending order
                  .slice(0, displayCount)
                  .map((task) => (
                    <div className="card p-2 mb-2" key={task.pk_taskid}>
                      <div className="card-body p-0">
                        <h5 className="card-title" style={{ color: "#000" }}>
                          {task.taskname}
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          {task.project_name} - {task.category}
                        </h6>
                        <p className="card-text">
                          <strong>Description:</strong> {task.task_description}
                        </p>

                        <p className="card-text">
                          <strong>Priority:</strong>{" "}
                          {task.task_priority || "N/A"}
                        </p>

                        <p className="card-text">
                          <strong>Status:</strong>
                          <span
                            style={
                              task.status === "C"
                                ? { color: "#387F39" }
                                : task.status === "P"
                                ? { color: "#FFB22C" }
                                : task.status === "O"
                                ? { color: "#FF0000" }
                                : { color: "black" }
                            }
                          >
                            {task.status === "C"
                              ? "Complete"
                              : task.status === "P"
                              ? "Pending"
                              : task.status === "O"
                              ? "Overdue"
                              : "Unknown"}
                          </span>
                        </p>
                        <p className="card-text">
                          <strong>Date:</strong>{" "}
                          {formatDate(task.task_startdate)} to
                          {formatDate(task.task_enddate)}
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <p>No recent activities found.</p>
              )}
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .task-overview-container {
          position: relative;
        }
        .task-sidebar {
          position: fixed;
          top: 5rem;
          right: 0;
          width: 30rem;
          height: 100%;
          background: #fff;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
          z-index: 1000;
          overflow-y: auto;
          padding: 20px;
          transition: transform 0.3s ease;
        }
        .task-sidebar-content {
          position: relative;
        }
        .task-close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
        .subtask-cards {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default Taskoverview;

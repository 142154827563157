import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Container, Accordion, Card } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import Nabvar from "./Navbar";
import Footer from "./footer";
import Plans from "./Plans";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";

const Pricing = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [accessData, setAccessData] = useState([]);
  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "15");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  const getAccessData = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSubscriptionPlansModule`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setAccessData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching access data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getAccessData();
    fetchSeoData(); 
  }, []);

  return (
    <>
      <Helmet>
  <title>{pageData ? pageData.basic_settings.tittle : "Loading..."}</title>
  <meta name="description" content={pageData ? pageData.basic_settings.meta_desc : "Loading..."} />
  <meta name="keywords" content={pageData ? pageData.keyword_settings.map(keyword => keyword.keyword).join(", ") : "Loading..."} />
  <link rel="canonical" href={pageData ? pageData.basic_settings.canonical_tag : "#"} />
  <meta property="og:title" content={pageData ? pageData.og_settings?.og_title || "Loading..." : "Loading..."} />
  <meta property="og:description" content={pageData ? pageData.og_settings?.og_desc || "Loading..." : "Loading..."} />
  <meta property="og:image" content={pageData ? pageData.og_settings?.og_image || "#" : "#"} />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
  {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}
</Helmet>
      <Nabvar />
      <div>
        <Plans />
        <div className="mt-md-4">
          <h5 className="fancy">Distribution of Plans</h5>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-9">
            <Container className="m-0 p-0">
              <Accordion>
                {accessData.map((module, index) => (
                  <Card className="m-0 p-2 corners corners--repeated-circles" key={index}>
                    <Card.Header
                      as={Accordion.Toggle}
                      eventKey={index.toString()}
                      className="m-0 p-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: ".2rem",
                        border: "1px solid #7b628f",
                        background: "#E5D4FF",
                      }}
                    >
                      <div style={{ color: "#3f1660" }}>{module.module}</div>
                      <div>
                        <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                      </div>
                    </Card.Header>

                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body
                        className="mt-2"
                        style={{
                          background: "#FEFAF6",
                          border: "1px solid rgb(170, 215, 189)",
                        }}
                      >
                        <div className="row">
                          <h6 className="col"></h6>
                          <table>
                            <thead>
                              <tr>
                                <th className="text-center" style={{ background: "#FEFAF6", color: "#FDDE55" }}></th>
                                <th
                                  className="text-center"
                                  style={{
                                    background: "#102C57",
                                    color: "#FDDE55",
                                    borderTopLeftRadius: "1rem",
                                    borderBottomLeftRadius: "1rem",
                                  }}
                                >
                                  Basic
                                </th>
                                <th className="text-center" style={{ background: "#102C57", color: "#FDDE55" }}>
                                  Premium
                                </th>
                                <th
                                  className="text-center"
                                  style={{
                                    background: "#102C57",
                                    color: "#FDDE55",
                                    borderTopRightRadius: "1rem",
                                    borderBottomRightRadius: "1rem",
                                  }}
                                >
                                  Enterprise
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {module.submodule.map((submodule, subIndex) => (
                                <tr className="col-4 text-center" key={subIndex}>
                                  <td className="m-0 p-0" style={{ border: "none", fontSize: "1rem" }}>
                                    {submodule.submodule_name}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.basic === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.premium === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                  <td className="text-center" style={{ border: "none" }}>
                                    {submodule.enterprise === "Y" ? (
                                      <IoMdCheckmarkCircleOutline style={{ color: "green", fontSize: "1.5rem" }} />
                                    ) : (
                                      <RxCrossCircled style={{ color: "red", fontSize: "1.5rem" }} />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Container>
          </div>
          <div className="col"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;

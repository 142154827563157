import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Table, ProgressBar, Card } from "react-bootstrap";
import Select from "react-select";
import { Pie, Bar, Line, Doughnut } from "react-chartjs-2";
import { saveAs } from "file-saver";
import swal from "sweetalert";
import { Link } from "react-router-dom";
const Budgetanalysis = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [budgetData, setBudgetData] = useState([]);

  // Mock initial data
  const mockData = [
    { id: 1, category: "Marketing", budget: 5000, spent: 3500, balance: 1500 },
    { id: 2, category: "Operations", budget: 7000, spent: 4500, balance: 2500 },
    { id: 3, category: "IT", budget: 10000, spent: 7500, balance: 2500 },
  ];

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setBudgetData(mockData);
    }, 1000);
  }, []);

  // Chart Data
  const pieData = {
    labels: budgetData.map((item) => item.category),
    datasets: [
      {
        label: "Budget Distribution",
        data: budgetData.map((item) => item.budget),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const barData = {
    labels: budgetData.map((item) => item.category),
    datasets: [
      {
        label: "Spent",
        data: budgetData.map((item) => item.spent),
        backgroundColor: "#36A2EB",
      },
      {
        label: "Remaining",
        data: budgetData.map((item) => item.balance),
        backgroundColor: "#FFCE56",
      },
    ],
  };

  const lineData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Marketing",
        data: [1000, 1500, 2000, 2500, 3000, 3500],
        borderColor: "#FF6384",
        fill: false,
      },
      {
        label: "IT",
        data: [2000, 3000, 4000, 5000, 6000, 7000],
        borderColor: "#36A2EB",
        fill: false,
      },
    ],
  };

  const donutData = {
    labels: budgetData.map((item) => item.category),
    datasets: [
      {
        label: "Budget Breakdown",
        data: budgetData.map((item) => item.spent),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  // Export to CSV
  const exportToCSV = () => {
    const csvData = [
      ["ID", "Category", "Total Budget", "Spent", "Remaining Balance"],
      ...budgetData.map((item) => [item.id, item.category, item.budget, item.spent, item.balance]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "budget_analysis.csv");
  };

  // Summary Stats
  const totalBudget = budgetData.reduce((sum, item) => sum + item.budget, 0);
  const totalSpent = budgetData.reduce((sum, item) => sum + item.spent, 0);
  const totalBalance = budgetData.reduce((sum, item) => sum + item.balance, 0);

  return (
    <div className="container mt-4">
     <div className="row mt-2">
        <h4 className="col">Enhanced Budget Analysis Dashboardl</h4>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/Add_Budget">
            <Button size="sm" variant="info">
              Make Budget
            </Button>
          </Link>
        </div>
      </div>

      <Row className="mb-3">
        <Col md={3}>
          <Form.Group controlId="categoryFilter">
            <Form.Label>Filter by Category</Form.Label>
            <Select
              options={budgetData.map((item) => ({
                value: item.category.toLowerCase(),
                label: item.category,
              }))}
              value={selectedCategory}
              onChange={(option) => setSelectedCategory(option)}
              placeholder="Select Category"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="dateRangeFilter">
            <Form.Label>Filter by Date Range</Form.Label>
            <Form.Control
              type="date"
              value={selectedDateRange}
              onChange={(e) => setSelectedDateRange(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={2} className=" mt-2 d-flex align-items-center">
          <Button variant="primary" className="w-100">
            Apply Filters
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover responsive className="mb-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Category</th>
            <th>Total Budget</th>
            <th>Spent</th>
            <th>Remaining Balance</th>
            <th>Utilization (%)</th>
          </tr>
        </thead>
        <tbody>
          {budgetData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.category}</td>
              <td>${item.budget}</td>
              <td>${item.spent}</td>
              <td>${item.balance}</td>
              <td>
                <ProgressBar
                  now={(item.spent / item.budget) * 100}
                  label={`${((item.spent / item.budget) * 100).toFixed(1)}%`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row>
        <Col md={6}>
          <h4 className="text-center">Budget Distribution (Pie Chart)</h4>
          <Pie data={pieData} />
        </Col>
        <Col md={6}>
          <h4 className="text-center">Spending Trends (Bar Chart)</h4>
          <Bar data={barData} />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <h4 className="text-center">Budget Trends (Line Chart)</h4>
          <Line data={lineData} />
        </Col>
        <Col md={6}>
          <h4 className="text-center">Spending Breakdown (Donut Chart)</h4>
          <Doughnut data={donutData} />
        </Col>
      </Row>

      <div className="mt-4 text-center">
        <Button variant="success" onClick={exportToCSV}>
          Export to CSV
        </Button>
      </div>
    </div>
  );
};

export default Budgetanalysis;

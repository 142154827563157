import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { VendoradminModal } from "./Modal";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const Vendormanagement = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [vendorData, setVendorData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const GetVendorDetails = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ firmid: user.firm_id });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetVendorDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setVendorData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetVendorDetails();
  }, []);
  const handleClick = (vendor) => {
    sessionStorage.setItem('selectedvendor', JSON.stringify(vendor));
    console.log(vendor);
  };
  return (
    <>
      <div className="row mt-2">
        <h4 className="col">Vendor Management</h4>
        <div className="col d-flex justify-content-end">
          <div>
            <Button size="sm" onClick={handleShow}>
              Add Vendor
            </Button>
          </div>
        </div>
      </div>

      <Table hover responsive className="m-2">
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Business Type<br/>Industry</th>
            <th>Registration Name</th>
            <th>GST</th>
            <th>Description</th>
            <th>Website URL</th>
            <th>Name / Mobile / Email</th>
            <th>Address</th>
            <th>PAN/ TAN</th>
          </tr>
        </thead>
        <tbody>
          {vendorData.map((vendor, index) => (
            <tr key={vendor.id}>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.vendor_name}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.buisness_type}<br/>  &   {vendor.industry}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.registration_name}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.gstin}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.description}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.website_url}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.contact_person_name}
                <br />
                {vendor.contact_person_mobile} <br />
                {vendor.contact_person_email}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                {vendor.address}
              </td>
              <td
                className="text-center align-middle"
                style={{
                  border: "none",
                  fontSize: ".8rem",
                  fontWeight: "700",
                }}
              >
                              {vendor.pan} &<br/>
                              {vendor.tan}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <VendoradminModal
        showModal={showModal}
        handleClose={handleClose}
        GetVendorDetails={GetVendorDetails}
      />
    </>
  );
};

export default Vendormanagement;

import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Nabvar from "./Navbar";
import { GlobalContext } from "../../Context/GlobalState";
import { Accordion, Card, Container } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

const Billpuncfaq = () => {
  const [faq, setfaq] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "3");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  const getfaq = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFaqSuperadmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setfaq(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    fetchSeoData(); 
    getfaq(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
  <title>{pageData ? pageData.basic_settings.tittle : "Loading..."}</title>
  <meta name="description" content={pageData ? pageData.basic_settings.meta_desc : "Loading..."} />
  <meta name="keywords" content={pageData ? pageData.keyword_settings.map(keyword => keyword.keyword).join(", ") : "Loading..."} />
  <link rel="canonical" href={pageData ? pageData.basic_settings.canonical_tag : "#"} />
  <meta property="og:title" content={pageData ? pageData.og_settings?.og_title || "Loading..." : "Loading..."} />
  <meta property="og:description" content={pageData ? pageData.og_settings?.og_desc || "Loading..." : "Loading..."} />
  <meta property="og:image" content={pageData ? pageData.og_settings?.og_image || "#" : "#"} />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
  {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}
</Helmet>

      <Nabvar />
      <h1 className="text-center mt-3" style={{ color: "darkblue" }}>
        Frequently Asked Questions
      </h1>

      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-6 col-10 d-flex justify-content-center col">
          <Container className="m-0 p-0">
            <Accordion>
              {faq.map((item) => (
                <Card className="m-2 p-2" key={item.pk_faq_id}>
                  <Accordion.Toggle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: ".2rem",
                      borderBottom: "1px solid rgb(170, 215, 189)",
                    }}
                    className="m-0 p-2"
                    as={Card.Header}
                    eventKey={item.pk_faq_id}
                  >
                    {item.question}
                    <div>
                      <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={item.pk_faq_id}>
                    <Card.Body
                      className="p-2"
                      style={{
                        background: "#FEFAF6",
                        border: "1px solid rgb(170, 215, 189)",
                        fontWeight: "600",
                        fontSize: ".8rem",
                        borderTop: "none",
                      }}
                    >
                      {item.answer}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Container>
        </div>
        <div className="col-md-5 d-flex justify-content-center mt-md-5">
          <img
            className="mainpic"
            alt="FAQs section"
            style={{ height: "20rem" }}
            src={require("../../../assets/images/billpunch_faq.png")}
          />
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpuncfaq;

import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { Container, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { IoIosRemoveCircle, IoMdAddCircle } from "react-icons/io";

const Addbudget = () => {
  const { user } = useContext(GlobalContext);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [entries, setEntries] = useState({
    January: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    February: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    March: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    April: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    May: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    June: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    July: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    August: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    September: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    October: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    November: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
    December: [
      {
        p_name: "",
        p_category: "",
        p_planned_budget: "",
        p_desc: "",
        p_priority: "",
        p_approval_status: "",
        p_remarks: "",
      },
    ],
  });
  const [expandedMonth, setExpandedMonth] = useState(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getProjects = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProjects(data.data);
      } else {
        console.error("Failed to fetch projects:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const projectOptions = projects.map((project) => ({
    value: project.pk_project_id,
    label: project.project_name,
  }));

  const handleSelectProjectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedProject(selectedOption.value);
    } else {
      console.error("Invalid project selection");
    }
  };

  const handleAddRow = (month) => {
    setEntries((prev) => {
      const newEntries = {
        ...prev,
        [month]: [
          ...(prev[month] || []),
          {
            p_name: "",
            p_category: "",
            p_planned_budget: "",
            p_desc: "",
            p_priority: "",
            p_approval_status: "",
            p_remarks: "",
          },
        ],
      };
      console.log("Updated entries for month:", month, newEntries);
      return newEntries;
    });
  };

  const handleRemoveRow = (month, index) => {
    setEntries((prev) => ({
      ...prev,
      [month]: prev[month].filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (month, index, field, value) => {
    console.log(`Changing ${field} for month ${month}, index ${index}:`, value);
    setEntries((prev) => {
      const updatedEntries = {
        ...prev,
        [month]: prev[month].map((entry, i) =>
          i === index ? { ...entry, [field]: value } : entry
        ),
      };
      console.log("Updated entries:", updatedEntries);
      return updatedEntries;
    });
  };

  const handleSubmit = async () => {
    try {
      const requests = Object.entries(entries).flatMap(([month, rows]) =>
        rows.map((row) => ({
          ...row,
          p_month: month,
        }))
      );

      if (requests.length === 0) {
        swal("Error", "No data to submit.", "error");
        return;
      }

      const requestBody = {
        p_firmid: user.firm_id,
        p_projectid: selectedProject,
        requests,
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddVendorItems`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === "0") {
        swal("Success", "Vendor items added successfully!", "success");
      } else {
        swal("Error", data.msg || "Failed to add vendor items.", "error");
      }
    } catch (error) {
      console.error("Error submitting vendor items:", error);
      swal("Error", "An error occurred while submitting the form.", "error");
    }
  };

  const toggleMonthAccordion = (month) => {
    setExpandedMonth(expandedMonth === month ? null : month);
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    console.log("Entries on load:", entries);
  }, [entries]);

  return (
    <>
      <div className="m-3 d-flex justify-content-end">
      <Link to={"/retailer/Budget_analysis"}>
              <button
                type="button"
                className="btn btn-sm justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2.5em",
                  // width: "8rem",
                  border: "None",
                  color: "#fff",
                }}
              >
                Back
              </button>
            </Link>
            </div>
    
      <div className="m-3">
        {!selectedProject ? (
          <div>
            <Select
              options={projectOptions}
              onChange={handleSelectProjectChange}
              placeholder="Select a project"
            />
          </div>
        ) : (
          <div>
            <div className="m-3 d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={() => setSelectedProject(null)}
              >
                Back to Select Project
              </Button>
            </div>
            <Container className="">
              {months.map((month) => (
                <div key={month} style={{ marginBottom: "10px" }}>
                  <Button
                    style={{
                      width: "100%",
                      textAlign: "left",
                      backgroundColor: "rgb(236, 246, 240)",
                      color: "#000",
                      border: "none",
                      padding: "10px",
                      fontSize: "16px",
                      border: "1px solid rgb(170, 215, 189)",
                    }}
                    onClick={() => toggleMonthAccordion(month)}
                  >
                    {month}
                  </Button>
                  {expandedMonth === month && (
                    <div
                      style={{
                        padding: "10px",
                        backgroundColor: "#f1f1f1",
                        border: "1px solid #ddd",
                      }}
                    >
                      <div
                        className="table-responsive"
                        style={{
                          borderRadius: ".4em",
                          border: "1px solid lightgrey",
                        }}
                      >
                        <table className="table table-bordered m-0 p-0">
                          <thead>
                            <tr style={{ borderBottom: "1px solid lightgrey" }}>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Name
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Category
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Planned Budget
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Description
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Priority
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Approval Status
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Remarks
                              </th>
                              <th
                                className="text-center"
                                style={{
                                  color: "#0074D9",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {entries[month].map((entry, index) => (
                              <tr
                                style={{ borderBottom: "1px solid lightgrey" }}
                                key={index}
                              >
                                {Object.keys(entry).map((field) => (
                                  <td
                                    key={field}
                                    className="text-center align-middle"
                                    style={{ border: "none" }}
                                  >
                                    <Form.Control
                                      type="text"
                                      value={entry[field]}
                                      onChange={(e) =>
                                        handleInputChange(
                                          month,
                                          index,
                                          field,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                ))}
                                <td
                                  className="text-center align-middle"
                                  style={{ border: "none" }}
                                >
                                  <IoIosRemoveCircle
                                    style={{ fontSize: "2rem", color: "red" }}
                                    onClick={() =>
                                      handleRemoveRow(month, index)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <IoMdAddCircle
                        style={{ color: "green", fontSize: "2rem" }}
                        onClick={() => handleAddRow(month)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Container>
            <div className="mt-3">
              <Button variant="success" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Addbudget;

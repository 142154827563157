import React, { Suspense, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { generateHashKey2, uniqueID2 } from "../utils/utilFuctions";

import Spinner from "./shared/Spinner";

import BankDetails from "./Bank Details/BankDetails";
import RechargeReport from "./Recharge Report/RechargeReport";
// import Aeps2 from "./AEPS/Aeps2";
// import Aeps2 from "./AEPS/Aeps3";
import Aeps2 from "./AEPS/Aeps4";
import MyProfile from "./my profile/MyProfile";
import Error404 from "./error-pages/Error404";
import Error500 from "./error-pages/Error500";
import Login2 from "../user-pages/Login2";
import AEPSsettlement from "./AEPSSettlement/AEPSSettlement";
import EKYC from "./Agent EKYC/EKYC";

import { checkSession, SESSION_TIMER } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import LoanUI from "./LoansAndMore/LoanAndMore";
import HelpAndSupport from "./HelpAndSupport/HelpAndSupport2";
import AgentCertificate from "../company/AgentCertificate";
import VirtualReport from "./virtual report/VirtualReport";
import Dashboard from "./dashboard/Dashboard";
import { TopupRequest } from "./topup/Topup3";
import RetailerCommission from "./retailer-commision/RetailerCommission";
import BbpsScreen from "./bbps-screen/BbpsScreen";
import Transactions from "./transactions/Transactions";
import IncomeCalculator from "./income-calculator/IncomeCalculator";
import MoneyTransfer from "./money-transfer/MoneyTransfer";
import AEPSReconcileReport from "./AEPS Reconcile Report/AEPSReconcileReport";
import PaymentGatewayReport from "./payment-gateway-report/PymentsGetwayReport";
import ServicesRequest from "./services-request/ServicesRequest";
// import { MyApp as FlightApp } from "../../Flights/src/App";
import Developer from "./developer/Developer";
// import ViewLoadAgent from "./view-load/ViewLoad";
import ViewLoadAgent from "./Topup View/TopupView";
import EPan from "./EPAN/EPan";
import CreditRequest from "./credit-request/CreditRequest";
import LedgerReport2 from "./ledger2/LedgerReport2";
import DMTHistory2 from "./DMT History 2/DMTHistory2";
import BBPSReport2 from "./BBPS Report 2/BBPSReport2";
import AEPSReport2 from "./AEPS Report 2/AEPSReport2";
import PGSettlement from "./PGSettlement/PGSettlement";
import EpanReport from "./EPanReport/EPanReport";
import PGSettlementReport from "./pg-settlement-report/PGSettlementReport";
import CMS from "./cms/Cms";
import CreditCardBillPayment from "./credit-card-bill-payment/CreditCardBillPayment";
import CreditCardBillPaymentReport from "./credit-card-bill-payment-report/CreditCardBillPaymentReport";
import FundHoldReport from "./fund_hold_report/AllFundHoldReport";
import VendorPaymentSystem from "./vendor_payment_system/VendorPaymentSystem";
import VendorAccountList from "./vendor_payment_system/VendorAccountList";
import AdminDashboard from "./SalaryManagement/AdminDashboard";
import BulkSalaryProcess from "./SalaryManagement/BulkSalaryproceed";
import ExcelUpdate from "./SalaryManagement/ExcelUpload";
import PayrollAdminAccess from "./SalaryManagement/PayrollAdminAccess";
import AttandancecalendraAdmin from "./SalaryManagement/AttandancecalendraAdmin";
import EmployeeListAdmin from "./SalaryManagement/EmployeeLIstAdmin";
import EmployeeLeave from "./SalaryManagement/EmployeeLeave";
import LeaveApprovalAdmin from "./SalaryManagement/LeaveapprovalAdmin";
import LeaveApprovalHr from "./SalaryManagement/LeaveApprovalHr";
import AttandancecalendraHR from "./SalaryManagement/AttandanceCalendraHR";
import ViewEmployeeprofile from "./SalaryManagement/ViewEmployeeprofile";
import EmployeeDashboard from "./SalaryManagement/EmployeeDashboard";
import HRDashboard from "./SalaryManagement/HRDashboard";
import EPFcalculator from "./SalaryManagement/EPFcalculator";
import AttandanceManagement from "./SalaryManagement/AttandanceManagement";
import ProcessSalary from "./SalaryManagement/PayrollAdmin";
import ExpenseDashboard from "./SalaryManagement/SalaryExpenseDashboard";
import EmplooyeeMail from "./SalaryManagement/EmployeeMail";
import AdminMail from "./SalaryManagement/AdminMail";
import HrMail from "./SalaryManagement/HrMail";
import EmployeeAttandance from "./SalaryManagement/EmployeeAttandance";
import ViewSalaryExpenseAdmin from "./SalaryManagement/ViewSalaryExpenseAdmin";
import ViewSalaryExpenseHR from "./SalaryManagement/ViewSalaryExpenseHR";
import Expenses from "./SalaryManagement/Expense";
import AddEmployee from "./SalaryManagement/EmployeList";
import Loginpayroll from "./SalaryManagement/Loginpayroll";
import Firmdata from "./SalaryManagement/FirmData";
import EmployeeFormHR from "./SalaryManagement/EmployeeFormHR";
import EmployeeFormAdmin from "./SalaryManagement/EmployeeFormAdmin";
import EmployeelistHR from "./SalaryManagement/EmployeListHR";
import ExpenseDashboardHR from "./SalaryManagement/ExpenseDashboardHR";
import ExpenseDashboardAdmin from "./SalaryManagement/ExpenseDashboardAdmin";
import { ViewExpenseTableAdmin } from "./SalaryManagement/SalaryTable";
import LandingPage from "./SalaryWeb/landingpage";
import PayrollHR from "./SalaryManagement/PayrollHR";
import PayrollAdmin from "./SalaryManagement/PayrollAdmin"
import Signup from "./SalaryWeb/Signup";
import Billpunchtable from "./SalaryManagement/table";
import Payrollprocesssalary from "./SalaryManagement/ProceessalaryTable";
import ExpenseDashboardemployee from "./SalaryManagement/Expensedashboardemployee";
import ExpensesEmployee from "./SalaryManagement/Expenseemployee";
import Viewexpanseemployee from "./SalaryManagement/ViewSalaryExpenseemployee";
import Payrollprocesssalaryhr from "./SalaryManagement/ProcesssalarytableHR";
import Createproject from "./SalaryManagement/Createproject";
import TrackingEmployee from "./SalaryManagement/TrackingEmployee";
import Createworkemployee from "./SalaryManagement/Createworkemployee";
import Employeeworkspace from "./SalaryManagement/Employeeworkspace";
import WorkDashboard from "./SalaryManagement/Workdashboard";
import WorkDashboardnew from "./SalaryManagement/Workdashnew";
import  { Dummy } from "./SalaryManagement/Dummy";
import { Subtask, Task } from "./SalaryManagement/Task";
import Accountledger from "./SalaryManagement/Accountledger";
import Expensepayroll from "./SalaryManagement/Expensepayrol";
import AccountledgerHR from "./SalaryManagement/AccountledgerHR";
import Expensepayrollhr from "./SalaryManagement/ExpensepayrollHr";
import Subtasks from "./SalaryManagement/Subtask";
import Activity from "./SalaryManagement/Activity";
import Privacypolicy from "./SalaryManagement/Policy";
import Privacypolicyemp from "./SalaryManagement/Privacypolicy";
import Taskmanagementadmin from "./SalaryManagement/TaskManagementadmin";
import Subtaskadmin from "./SalaryManagement/Adminsubtask";
import Activitymanagementadmin from "./SalaryManagement/Activitymanagementadmin";
import Superadmindashboard from "./Super admin/DashboardSuperadmin";
import Firmdashboard from "./Super admin/Firmdashboard";
import LoadRequest from "./SalaryManagement/Loadrequest";
import Firmdaccess from "./Super admin/Firmaccess";
import Salaryarrear from "./SalaryManagement/Salaryarrear";
import Salarymanagement from "./SalaryManagement/SalaryManagement";
import Salarydata from "./SalaryManagement/Salarydata";
import Salarymanagementhr from "./SalaryManagement/SalaryManagementhr";
import Salarydatahr from "./SalaryManagement/Salarydatahr";
import Reacentqueries from "./Super admin/RecentQuery";
import Balanceleave from "./SalaryManagement/Balanceleave";
import Salaryslipemployee from "./SalaryManagement/Salaryslipemployee";
import Loginqueries from "./Super admin/Loginqueries";
import Balanceleavehr from "./SalaryManagement/BalanceleaveHr";
import Salaryarrearhr from "./SalaryManagement/Salaryarrearhr";
import Admintax from "./SalaryManagement/Taxadmin";
import Form16admin from "./SalaryManagement/Form16admin";
import Generateform16admin from "./SalaryManagement/Generateform16admin";
import Createworkspaceadmin from "./SalaryManagement/Createworkspaceadmin";
import Loanadmin from "./SalaryManagement/Loanadmin";
import Recordloanadmin from "./SalaryManagement/Recordloanadmin";
import Loaddetailadmin from "./SalaryManagement/Loandetailadmin";
import Statutorycomponentsadmin from "./SalaryManagement/AdminStatutoryComponents";
import Epfformadmin from "./SalaryManagement/Epfadminform";
import Esiadminform from "./SalaryManagement/Esiadminform";
import Salarycomponentsadmin from "./SalaryManagement/AdminSalaryComponent";
import Adminearning from "./SalaryManagement/Adminearning";
import AdminReimbursement from "./SalaryManagement/AdminReimbursement";
import Editsdminearning from "./SalaryManagement/EditAdminEarning";
import Admindeduction from "./SalaryManagement/AdminDeduction";
import Salarytemplateadmin from "./SalaryManagement/AdminSalarttemplate";
import Newsalarytemplateadmin from "./SalaryManagement/AdminNewalarytemplate";
import Adminsetting from "./SalaryManagement/AdminSetting";
import Employeesetting from "./SalaryManagement/EmployeeSettings";
import Employeeproofinvestment from "./SalaryManagement/EmployeeProofinvestment";
import Proofinvestmentform from "./SalaryManagement/Proofinvestmentempform";
import Payouthistory from "./SalaryManagement/PayoutHistory";
import Itdeclaration from "./SalaryManagement/ItDeclarationform";
import Superadminsetting from "./Super admin/SuperadminSetting";
import Admininvestment from "./SalaryManagement/AdminInvestment";
import Itdeclarationform from "./SalaryManagement/ITdeclarationFormAdmin";
import Wfhworkupdate from "./SalaryManagement/WFHWorkupdate";
import Reviewwfh from "./SalaryManagement/ReviewWFHtask";
import Reviewwfhadmin from "./SalaryManagement/ReviewWFHtaskadmin";
import Payments from "./SalaryManagement/Payment";
import Payouthistoryone from "./SalaryManagement/Payouthistor1";
import Officeinventory from "./SalaryManagement/OfficeInventory";
import ItemsofficeInventory from "./SalaryManagement/ItemsOfficeinventory";
import Employeebalanceleave from "./SalaryManagement/EmployeeBalanceleave";
import Taskoverview from "./SalaryManagement/Taskoverview";
import Inactiveemployee from "./SalaryManagement/InactiveEmployee";
import AddBlog from "./SalaryManagement/Blogs";
import Customblogs from "./SalaryManagement/Customblog";
import Customblog1 from "./SalaryManagement/testblog";
import Editblogs from "./SalaryManagement/Editblog";
import Test from "./SalaryManagement/Test";
import Blogauthor from "./Super admin/Blogauthor";
import Vendormanagement from "./SalaryManagement/VendorManagement";
import Requirements from "./SalaryManagement/Requirements";
import Vendoritems from "./SalaryManagement/Vendoritems";
import Addvendorbill from "./SalaryManagement/AddVendorbill";
import Budgetanalysis from "./SalaryManagement/BudgetAnalysis";
import Viewvendorbill from "./SalaryManagement/ViewVendorbill";
import Addbudget from "./SalaryManagement/AddBudget";

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const checkSession2 = async (sessionId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/check_session.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addSession = async () => {
    const sessionId = uniqueID2();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/add_session.aspx",
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        sessionStorage.setItem("sessionId", sessionId);
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionId = sessionStorage.getItem("sessionId");
      if (sessionId) {
        // checkSession2(sessionId);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const sessionId = sessionStorage.getItem("sessionId");
    // if (!sessionId) {
    // addSession();
    //  }
  }, [user]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Retailer */}
        <Route exact path="/retailer/Add_Budget" component={Addbudget}/>
        <Route exact path="/retailer/View_Vendor_Bill" component={Viewvendorbill}/>
        <Route exact path="/retailer/Budget_analysis" component={Budgetanalysis}/>
        <Route exact path="/retailer/Add_Vendor_bill" component={Addvendorbill}/>
        <Route exact path="/retailer/Vendor_Items" component={Vendoritems}/>
        <Route exact path="/retailer/Project_Requirements" component={Requirements}/>
        <Route exact path="/retailer/Vendor_Management" component={Vendormanagement}/>
        <Route exact path="/retailer/Test" component={Test}/>
        <Route exact path="/retailer/Edit_blogs" component={Editblogs}/>
        <Route exact path="/retailer/Custom_Blogs1" component={Customblog1}/>
        <Route exact path="/retailer/Custom_Blogs" component={Customblogs}/>
        <Route exact path="/retailer/Blogs" component={AddBlog}/>
        <Route exact path="/retailer/Inactive_Employee" component={Inactiveemployee}/>
        <Route exact path="/retailer/Task_Overview" component={Taskoverview}/>
        <Route exact path="/retailer/Employee_Balance_Leave" component={Employeebalanceleave}/>
        <Route exact path="/retailer/Items_office_Inventory" component={ItemsofficeInventory}/>
        <Route exact path="/retailer/Office_inventory" component={Officeinventory}/>
        <Route exact path="/retailer/Payments" component={Payments}/>
        <Route exact path="/retailer/Review_Wfh_Work" component={Reviewwfhadmin}/>
        <Route exact path="/retailer/Review_Wfh_Work_Update" component={Reviewwfh}/>
        <Route exact path="/retailer/Wfh_Work_Update" component={Wfhworkupdate}/>
        <Route exact path="/retailer/Admin_Itdeclarationform" component={Itdeclarationform}/>
        <Route exact path="/retailer/Admin_Investment" component={Admininvestment}/>
        <Route exact path="/retailer/Super_admin_setting" component={Superadminsetting}/>
        <Route exact path="/retailer/It_declaration" component={Itdeclaration}/>
        <Route exact path="/retailer/Payout_History1" component={Payouthistoryone}/>
<Route exact path="/retailer/Payout_History" component={Payouthistory}/>
<Route exact path="/retailer/Investment_Form" component={Proofinvestmentform}/>
<Route exact path="/retailer/Investment" component={Employeeproofinvestment}/>
<Route exact path="/retailer/Employee_Settings" component={Employeesetting}/>
<Route exact path="/retailer/Admin_Setting" component={Adminsetting}/>
<Route exact path="/retailer/New_Salary_template" component={Newsalarytemplateadmin}/>
<Route exact path="/retailer/Salary_template" component={Salarytemplateadmin}/>
<Route exact path="/retailer/Admin_Deduction" component={Admindeduction}/>
<Route exact path="/retailer/Admin_Reimbursement" component={AdminReimbursement}/>
<Route exact path="/retailer/Admin_Edit_Earning_form" component={Editsdminearning}/>
<Route exact path="/retailer/Admin_Earning_form" component={Adminearning}/>
<Route exact path="/retailer/Esi_form_admin" component={Esiadminform}/>
<Route exact path="/retailer/Epf_form_admin" component={Epfformadmin}/>
<Route exact path="/retailer/Salary_components" component={Salarycomponentsadmin}/>
<Route exact path="/retailer/Statutory_components" component={Statutorycomponentsadmin}/>
<Route exact path="/retailer/Load_detail_admin" component={Loaddetailadmin}/>
<Route exact path="/retailer/Record_loan_admin" component={Recordloanadmin}/>
<Route exact path="/retailer/Loan" component={Loanadmin}/>
<Route exact path="/retailer/Create_workspace_admin" component={Createworkspaceadmin}/>
<Route exact path="/retailer/Generate_form16_admin" component={Generateform16admin}/>
 <Route exact path="/retailer/Form16" component={Form16admin}/>
<Route exact path="/retailer/Tax" component={Admintax}/>
<Route exact path="/retailer/Balance_leave_hr" component={Balanceleavehr}/>
 <Route exact path="/retailer/Login_queries" component={Loginqueries}/>
<Route exact path="/retailer/Salaryslip" component={Salaryslipemployee}/>
<Route exact path="/retailer/Balance_leave" component={Balanceleave}/>
 <Route exact path="/retailer/Reacent_queries" component={Reacentqueries}/>
 <Route exact path="/retailer/SalaryManagement" component={Salarymanagementhr}/>
 <Route exact path="/retailer/SalaryData" component={Salarydatahr}/>
 <Route exact path="/retailer/Salary_data" component={Salarydata}/>
 <Route exact path="/retailer/Salary_management" component={Salarymanagement}/>
 <Route exact path="/retailer/Arrear_salary_hr" component={Salaryarrearhr}/>
 <Route exact path="/retailer/Arrear_salary" component={Salaryarrear}/>
 <Route exact path="/retailer/Firmd_access" component={Firmdaccess}/>
 <Route exact path="/retailer/LoadRequest" component={LoadRequest}/>
 <Route exact path="/retailer/Firmdashboard" component={Firmdashboard}/>
 <Route exact path="/retailer/Blog_Author" component={Blogauthor}/>
 <Route exact path="/retailer/Superadmin_dashboard" component={Superadmindashboard}/>
 <Route exact path="/retailer/Activity_Management" component={Activitymanagementadmin}/>
 <Route exact path="/retailer/Subtask_management" component={Subtaskadmin}/>
 <Route exact path="/retailer/Task_management" component={Taskmanagementadmin}/>
 <Route exact path="/retailer/Privacy_policy" component={Privacypolicyemp}/>
 <Route exact path="/retailer/Privacypolicy" component={Privacypolicy}/>
 <Route exact path="/retailer/Activity" component={Activity}/>
 <Route exact path="/retailer/Subtasks" component={Subtasks}/>
 <Route exact path="/retailer/Expensepayrollhr" component={Expensepayrollhr}/>
 <Route exact path="/retailer/AccountledgerHR" component={AccountledgerHR}/>
 <Route exact path="/retailer/Expensepayroll" component={Expensepayroll}/>
 <Route exact path="/retailer/Accountledger" component={Accountledger}/>
 <Route exact path="/retailer/Subtask" component={Subtask}/>
 <Route exact path="/retailer/Dummy" component={Dummy}/>
 <Route exact path="/retailer/WorkDashboardnew" component={WorkDashboardnew}/>
 <Route exact path="/retailer/WorkDashboard" component={WorkDashboard}/>
 <Route exact path="/retailer/Employeeworkspace" component={Employeeworkspace}/>
 <Route exact path="/retailer/Createworkemployee" component={Createworkemployee}/>
 <Route exact path="/retailer/TrackingEmployee" component={TrackingEmployee}/>
 <Route exact path="/retailer/Createproject" component={Createproject}/>
 <Route exact path="/retailer/ExpensesEmployee" component={ExpensesEmployee}/>
 <Route exact path="/retailer/ExpenseDashboardemployee" component={ExpenseDashboardemployee}/>
 <Route exact path="/retailer/Payrollprocesssalaryhr" component={Payrollprocesssalaryhr}/>
 <Route exact path="/retailer/Payrollprocesssalary" component={Payrollprocesssalary}/>
 <Route exact path="/retailer/billTable" component={Billpunchtable}/>
 <Route exact path="/retailer/BulkSalaryProcess" component={BulkSalaryProcess}/>
 {/* <Route exact path="/retailer/LandingPage" component={LandingPage}/> */}
        <Route exact path="/retailer/ExcelUpdate" component={ExcelUpdate}/>
        <Route exact path="/retailer/PayrollAdminAccess" component={PayrollAdminAccess}/>
        <Route exact path="/retailer/AttandancecalendraAdmin" component={AttandancecalendraAdmin}/>
        <Route exact path="/retailer/EmployeeListAdmin" component={EmployeeListAdmin}/>
        <Route exact path="/retailer/AdminDashboard" component={AdminDashboard}/>
        <Route exact path="/retailer/EmployeeLeave" component={EmployeeLeave}/>
        <Route exact path="/retailer/LeaveApprovalAdmin" component={LeaveApprovalAdmin}/>
        <Route exact path="/retailer/LeaveApprovalHr" component={LeaveApprovalHr}/>
        <Route exact path="/retailer/AttandancecalendraHR" component={AttandancecalendraHR}/>
        <Route exact path="/retailer/ViewEmployeeprofile" component={ViewEmployeeprofile}/>
        <Route exact path="/retailer/EmployeeDashboard" component={EmployeeDashboard}/>
        <Route exact path="/retailer/HRDashboard" component={HRDashboard}/>
        <Route exact path="/retailer/EPFcalculator" component={EPFcalculator}/>
        <Route exact path="/retailer/AttandanceManagement" component={AttandanceManagement}/>
        <Route exact path="/retailer/ProcessSalary" component={ProcessSalary}/>
       
        <Route
          exact
          path="/retailer/ExpenseDashboard"
          component={ExpenseDashboard}
        />
        <Route exact path="/retailer/EmployeeMail" component={EmplooyeeMail}/>
        <Route exact path="/retailer/AdminMail" component={AdminMail}/>
        <Route exact path="/retailer/HrMail" component={HrMail}/>
        <Route exact path="/retailer/EmployeeAttandance" component={EmployeeAttandance}/>

        <Route exact path="/retailer/Viewexpanseemployee" component={Viewexpanseemployee}/>
        <Route exact path="/retailer/ViewSalaryExpenseAdmin" component={ViewSalaryExpenseAdmin}/>
        <Route exact path="/retailer/ViewSalaryExpenseHR" component={ViewSalaryExpenseHR}/>
        <Route exact path="/retailer/Expense" component={Expenses} />
        <Route exact path="/retailer/ViewExpense" component={ViewExpenseTableAdmin} />
        <Route exact path="/retailer/PayrollAdmin" component={PayrollAdmin} />
        <Route exact path="/retailer/PayrollHR" component={PayrollHR} />
        <Route exact path="/retailer/ExpenseDashboardAdmin" component={ExpenseDashboardAdmin} />
        <Route exact path="/retailer/ExpenseDashboardHR" component={ExpenseDashboardHR} />
        <Route exact path="/retailer/EmployeelistHR" component={EmployeelistHR} />
       
        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />
        <Route exact path="/retailer/EmployeeFormAdmin" component={EmployeeFormAdmin} />
        <Route exact path="/retailer/EmployeeFormHR" component={EmployeeFormHR} />
        <Route path="/retailer/FirmData" component={Firmdata} />
        {/* <Route path="/retailer/LoginForget" component={LoginForget} /> */}
        <Route path="/retailer/Loginpayroll" component={Loginpayroll} />
        <Route path="/retailer/Signup" component={Signup} />
        {/* <Route path="/retailer/LoginNew" component={LoginNew} /> */}
        <Route exact path="/retailer/AddEmployees" component={AddEmployee} />
       












        <Route exact path="/retailer/dashboard" component={Dashboard} />
        <Route exact path="/retailer/transactions" component={Transactions} />
        <Route
          exact
          path="/retailer/money-transfer"
          component={MoneyTransfer}
        />
        <Route
          exact
          path="/retailer/income-calculator"
          component={IncomeCalculator}
        />

        <Route exact path="/retailer/vendor-payment" component={VendorPaymentSystem} />
        <Route exact path="/retailer/vendor-accounts" component={VendorAccountList} />
        <Route exact path="/retailer/aeps" component={Aeps2} />
        <Route exact path="/retailer/cms" component={CMS} />
        <Route exact path="/retailer/credit-request" component={CreditRequest} />

        <Route
          exact
          path="/retailer/aeps-settlement"
          component={AEPSsettlement}
        />

        <Route
          exact
          path="/retailer/pg-settlement"
          component={PGSettlement}
        />

        <Route
          exact
          path="/retailer/fund-transfer"
          component={AEPSsettlement}
        />
        <Route
          exact
          path="/retailer/Help-And-Support"
          component={HelpAndSupport}
        />

        <Route exact path="/retailer/EKYC" component={EKYC} />
        <Route exact path="/retailer/e_pan" component={EPan} />
        <Route exact path="/retailer/credit_card_bill_payment" component={CreditCardBillPayment} />

        <Route
          exact
          path="/retailer/services_request"
          component={ServicesRequest}
        />

        {/* <Route exact path="/retailer/flight" component={FlightApp} /> */}


        <Route exact path="/retailer/LoansAndMore" component={LoanUI} />

        <Route exact path="/retailer/bill_payment" component={BbpsScreen} />
        {/* Offline BBPS */}


        <Route exact path="/retailer/topup_request" component={TopupRequest} />
        <Route exact path="/retailer/topup_view" component={ViewLoadAgent} />
        {/* Virtual Report */}
        <Route
          exact
          path="/retailer/virtual_report"
          component={VirtualReport}
        />
        <Route exact path="/retailer/bank_details" component={BankDetails} />
        <Route exact path="/retailer/my_profile" component={MyProfile} />

        <Route exact path="/retailer/reports/dmt" component={DMTHistory2} />
        <Route exact path="/retailer/reports/aeps" component={AEPSReport2} />
        <Route
          exact
          path="/retailer/reports/pg"
          component={PaymentGatewayReport}
        />
        <Route
          exact
          path="/retailer/reports/aeps-reconcile"
          component={AEPSReconcileReport}
        />
        <Route exact path="/retailer/bbps_history" component={BBPSReport2} />
        <Route
          exact
          path="/retailer/recharge_history"
          component={RechargeReport}
        />
        <Route
          exact
          path="/retailer/reports/epan_report"
          component={EpanReport}
        />
         <Route
          exact
          path="/retailer/reports/credit_card_bill_payment_report"
          component={CreditCardBillPaymentReport}
        />
        <Route
          exact
          path="/retailer/reports/pg_settlement_report"
          component={PGSettlementReport}
        />
          <Route
          exact
          path="/retailer/reports/fund_hold_report"
          component={FundHoldReport}
        />
        <Route exact path="/retailer/ledger" component={LedgerReport2} />
        <Route
          exact
          path="/retailer/certificate"
          component={AgentCertificate}
        />

        {/* Developer */}
        <Route exact path="/retailer/developer" component={Developer} />

        <Route path="/user-pages/login-2" component={Login2} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Redirect to="/user-pages/login-2" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;

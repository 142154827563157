import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row, Col, Table, Modal } from "react-bootstrap";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { IoIosRemoveCircle } from "react-icons/io";
import swal from "sweetalert";
const AddVendorBill = () => {
  const [chequeImg, setChequeImg] = useState(null);
  const [billimg, setBillImg] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [chequeurl, setchequeurl] = useState("");
  const [paymentMode, setPaymentMode] = useState("cash");
  const [vendorData, setVendorData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [vendorItems, setVendorItems] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [Finaltotal, setFinaltotal] = useState("");
  const [Hsn, setHsn] = useState("");
  const [Tax, setTax] = useState("");
  const [Price, setPrice] = useState("");
  const [items, setItems] = useState([
    {
      itemName: "",
      quantity: "",
      amount: "",
      HSN: "",
      cgst: "",
      sgst: "",
      taxRate: "",
      total: "",
      invoiceNo: "",
      invoiceDate: "",
      selectedItem: null,
    },
    {
      itemName: "",
      quantity: "",
      amount: "",
      HSN: "",
      cgst: "",
      sgst: "",
      taxRate: "",
      total: "",
      invoiceNo: "",
      invoiceDate: "",
      selectedItem: null,
    },
  ]);
  const [formData, setFormData] = useState({
    senderName: "",
    receiverName: "",
    bankName: "",
    accountNumber: "",
    chequeNumber: "",
    chequeAmount: "",
    chequeDate: "",
    chequeImg: null,
    UTR: "",
    transamount: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChequeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      setChequeImg(URL.createObjectURL(file));
      handleImageUpload(file, fileType, "Cheque Image");
    }
  };
  

  const handleSelectChange = (index, selectedOption) => {
    if (selectedOption?.value === "add_item") {
      setShowAddItemModal(true);
    } else {
      const updatedItems = [...items];
      const selectedItem = vendorItems.find(
        (item) => item.value === selectedOption?.value
      );

      if (selectedItem) {
        const tax = parseFloat(selectedItem.tax) || 0;
        const dividedTax = tax / 2;

        updatedItems[index] = {
          ...updatedItems[index],
          itemName: selectedOption.value,
          HSN: selectedItem.hsn_code,
          amount: selectedItem.price,
          cgst: dividedTax,
          sgst: dividedTax,
        };
      } else {
        updatedItems[index] = {
          ...updatedItems[index],
          itemName: "",
          HSN: "",
          amount: "",
          cgst: "",
          sgst: "",
        };
      }

      setItems(updatedItems);
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    const amount = parseFloat(updatedItems[index].amount) || 0;
    const quantity = parseInt(updatedItems[index].quantity) || 0;
    const cgst = parseFloat(updatedItems[index].cgst) || 0;
    const sgst = parseFloat(updatedItems[index].sgst) || 0;

    if (amount > 0 && quantity > 0) {
      const taxableAmount = amount * quantity;
      const cgstAmount = (taxableAmount * cgst) / 100;
      const sgstAmount = (taxableAmount * sgst) / 100;
      const total = taxableAmount + cgstAmount + sgstAmount;

      updatedItems[index].taxableAmount = taxableAmount.toFixed(2);
      updatedItems[index].cgstAmount = cgstAmount.toFixed(2);
      updatedItems[index].sgstAmount = sgstAmount.toFixed(2);
      updatedItems[index].total = total.toFixed(2);
    } else {
      updatedItems[index].taxableAmount = "";
      updatedItems[index].cgstAmount = "";
      updatedItems[index].sgstAmount = "";
      updatedItems[index].total = "";
    }

    setItems(updatedItems);
    const newFinalTotal = updatedItems
      .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
      .toFixed(2);
    setFinaltotal(newFinalTotal);
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption.value);
    setSelectedVendorDetails(selectedOption.details);
    const newInvoiceNumber = generateInvoiceNumber(selectedOption.label);
    setInvoiceNumber(newInvoiceNumber);
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        itemName: "",
        quantity: "",
        amount: "",
        HSN: "",
        cgst: "",
        sgst: "",
        taxRate: "",
        total: "",
        invoiceNo: "",
        invoiceDate: "",
      },
    ]);
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const paymentOptions = [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "IMPS", label: "IMPS" },
    { value: "NEFT", label: "NEFT" },
    { value: "RTGS", label: "RTGS" },
  ];
  const GetVendorDetails = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ firmid: user.firm_id });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetVendorDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        const vendorOptions = data.data.map((vendor) => ({
          value: vendor.id,
          label: vendor.vendor_name,
          details: vendor,
        }));
        setVendorData(vendorOptions);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error:", error);
      setTransactionLoaderState(false);
    }
  };
  const Getvendormasteritems = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetVendorExpenseItems`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const items = data.data.map((item) => ({
          value: item.item_name,
          label: item.item_name,
          hsn_code: item.hsn_code,
          tax: item.tax,
          price: item.price,
        }));
        setVendorItems(items);
      } else {
        setVendorItems([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const generateInvoiceNumber = (vendorName) => {
    if (!vendorName) return "";
    const vendorPrefix = vendorName.slice(0, 3).toUpperCase();
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    return `${vendorPrefix}-${randomNumber}`;
  };
  const handleAddItemSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!newItemName.trim() || !Hsn.trim()) {
        swal("Error", "Please fill in all fields.", "error");
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        itemname: newItemName,
        hsncode: Hsn,
        tax: Tax,
        price: Price,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddVendorExpenseItems`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("API Response:", data);

      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        const resultMessage = data.result?.[0]?.message || "Success";
        swal("Success", resultMessage, "success");
        setShowAddItemModal(false);
        setNewItemName("");
        setHsn("");
        setPrice("");
        setTax("");
        Getvendormasteritems();
      }
    } catch (error) {
      console.log("Error:", error);
      swal("Error", "An error occurred while adding the item.", "error");
    }
  };
  const handleSubmit = async () => {
    if (!selectedProject) {
      swal("Error", "Please select a project.", "error");
      return;
    }
  
    if (!selectedVendor || !invoiceNumber || !invoiceDate ) {
      swal("Error", "Please fill all required fields.", "error");
      return;
    }
    const filteredItems = items.filter(
      (item) =>
        item.itemName.trim() !== "" &&
        item.quantity.trim() !== "" &&
        item.amount.trim() !== ""
    );
  
    const requestBody = {
      firmid: user.firm_id,
      projectid: selectedProject,
      vendorid: selectedVendor || "",
      trans_type: paymentMode || "",
      cash_amount: paymentMode === "cash" ? formData.amount || "0" : "0",
      cheque_number:
        paymentMode === "cheque" ? formData.chequeNumber || "" : "0",
      cheque_date:
        paymentMode === "cheque" ? formData.chequeDate || "" : "2000-01-01",
      bank_name: paymentMode === "cheque" ? formData.bankName || "" : "0",
      bank_ifsc: paymentMode === "cheque" ? formData.bankIfsc || "" : "0",
      cheque_amount:
        paymentMode === "cheque" ? formData.chequeAmount || "0" : "0",
      cheque_url: paymentMode === "cheque" ? chequeurl || "" : "0",
      bill_url: imageUrl || "",
      trans_amount: ["IMPS", "NEFT", "RTGS"].includes(paymentMode)
        ? formData.transamount || "0"
        : "0",
      trans_rrn: ["IMPS", "NEFT", "RTGS"].includes(paymentMode)
        ? formData.UTR || ""
        : "",
      requests: filteredItems.map((item) => ({
        item: item.itemName || "",
        item_desc: "",
        qty: item.quantity || "",
        per_value: item.amount || "",
        total: item.total || "",
        cgst_percent: item.cgst || "",
        cgst_amount: item.cgstAmount || "",
        sgst_percent: item.sgst || "",
        sgst_amount: item.sgstAmount || "",
        hsn_code: item.HSN || "",
      })),
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddVendorItems`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === "0") {
        if (data.result[0].result === "Y") {
          swal(
            "Success",
            data.result[0].message || "Vendor items added successfully!",
            "success"
          ).then(() => {
            // Reset form fields
            setItems([
              {
                itemName: "",
                quantity: "",
                amount: "",
                HSN: "",
                cgst: "",
                sgst: "",
                taxRate: "",
                total: "",
                invoiceNo: "",
                invoiceDate: "",
                selectedItem: null,
              },
              {
                itemName: "",
                quantity: "",
                amount: "",
                HSN: "",
                cgst: "",
                sgst: "",
                taxRate: "",
                total: "",
                invoiceNo: "",
                invoiceDate: "",
                selectedItem: null,
              },
            ]);
            setFormData({
              senderName: "",
              receiverName: "",
              bankName: "",
              accountNumber: "",
              chequeNumber: "",
              chequeAmount: "",
              chequeDate: "",
              chequeImg: null,
            });
            setSelectedVendor(null);
            setSelectedVendorDetails({});
            setInvoiceNumber("");
            setInvoiceDate(new Date().toISOString().split("T")[0]);
            setPaymentMode("cash");
            setSelectedProject(null);
          });
        } else {
          swal("Error", data.msg || "Failed to add vendor items.", "error");
        }
      } else {
        swal("Error", data.msg || "Failed to add vendor items.", "error");
      }
    } catch (error) {
      console.error("Error submitting vendor items:", error);
      swal("Error", "An error occurred while submitting the form.", "error");
    }
  };

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const getProjects = async () => {
    console.log("getProjects called");
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      console.log("API response data:", data);
      if (data.status === "0") {
        setProjects(data.data);
        console.log("Project data set:", data.data);
      } else {
        console.error("Failed to fetch projects:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const projectOptions = projects.map((project) => ({
    value: project.pk_project_id,
    label: project.project_name,
  }));

  const handleSelectprojectChange = (selectedOption) => {
    setSelectedProject(selectedOption.value);
    console.log("Selected Project ID:", selectedOption.value);
  };
  useEffect(() => {
    getProjects();
    Getvendormasteritems();
    GetVendorDetails();
    const todayDate = new Date().toISOString().split("T")[0];
    setInvoiceDate(todayDate);
  }, []);
  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };
  const handleImageUpload = async (file, fileType, imageType) => {
    const byteArray = await convertToByteArray(file);
    const raw = JSON.stringify({
      firmid: user.firm_id,
      userid: user.userid,
      image_type: imageType, 
      file_type: fileType,
      image_data: byteArray,
    });
  
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + btoa(user.username + ":" + user.password)
    );
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };
  
    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ImageUpload`,
        requestOptions
      );
      const result = await response.json();
      if (result.status === "0") {
        swal("Success", result.msg, "success");
        if (imageType === "Cheque Image") {
          setchequeurl(result.image_url); 
        } else if (imageType === "Expense") {
          setImageUrl(result.image_url); 
        }
      } else {
        swal("Error", result.msg, "error");
      }
    } catch (error) {
      console.error(error);
      swal("Error", "Failed to upload image", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  

  const handlebillUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      setBillImg(file);
      handleImageUpload(file, fileType, "Expense");
    }
  };
  return (
    <>
      <div className="row mt-2">
        <h4 className="col">Add Bill</h4>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/View_Vendor_Bill">
            <Button size="sm" variant="info">
              Back
            </Button>
          </Link>
        </div>
      </div>

      <div className="m-2 p-3 border rounded">
        <h5>Billing Details</h5>
        <Form>
          <Row className="p-0">
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Vendor</Form.Label>
                <Select
                  options={vendorData}
                  value={vendorData.find(
                    (option) => option.value === selectedVendor
                  )}
                  onChange={handleVendorChange}
                  placeholder="Select Vendor"
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Invoice No.</Form.Label>
                <Form.Control
                  value={invoiceNumber}
                  type="text"
                  placeholder="Enter Invoice No."
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="p-0">
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Select Project</Form.Label>
                <Select
                  options={projectOptions}
                  onChange={handleSelectprojectChange}
                  placeholder="Select a project"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>GSTIN</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedVendorDetails.gstin || ""}
                  placeholder="Enter GST"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>TAN</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter TAN"
                  value={selectedVendorDetails.pan || ""}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>PAN</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PAN"
                  value={selectedVendorDetails.tan || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="p-0">
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label> Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={selectedVendorDetails.address || ""}
                  placeholder="Enter Address"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group className="mb-3">
                <Form.Label>Upload Bill</Form.Label>
                <Form.Control type="file" onChange={handlebillUpload} />
              </Form.Group>
            </Col>
          </Row>

          <h5>Item Details</h5>
          <Table bordered className="mb-2">
            <colgroup>
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Item Name</th>
                <th style={{ width: "7rem" }}>HSN</th>
                <th>Quantity</th>
                <th>Amount (per item)</th>

                <th style={{ width: "3rem" }}>CGST (%)</th>
                <th>CGST Amount</th>
                <th style={{ width: "3rem" }}>SGST (%)</th>
                <th>SGST Amount</th>
                <th>Taxable Amount</th>
                <th style={{ width: "9rem" }}>Total</th>
                <th className="p-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Select
                      options={[
                        { value: "add_item", label: "+ Add Item" },
                        ...vendorItems,
                      ]}
                      onChange={(selectedOption) =>
                        handleSelectChange(index, selectedOption)
                      }
                      placeholder="Select Item"
                      isClearable
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="HSN"
                      value={item.HSN}
                      onChange={(e) =>
                        handleItemChange(index, "HSN", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      placeholder="Qty"
                      value={item.quantity}
                      onChange={(e) =>
                        handleItemChange(index, "quantity", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      placeholder="Amt"
                      value={item.amount}
                      onChange={(e) =>
                        handleItemChange(index, "amount", e.target.value)
                      }
                    />
                  </td>

                  <td>
                    <Form.Control
                      type="number"
                      value={item.cgst}
                      onChange={(e) =>
                        handleItemChange(index, "cgst", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="CGST Amt"
                      value={item.cgstAmount}
                      readOnly
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.sgst}
                      onChange={(e) =>
                        handleItemChange(index, "sgst", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="SGST Amt"
                      value={item.sgstAmount}
                      readOnly
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Taxable Amt"
                      value={item.taxableAmount}
                      readOnly
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Total"
                      value={item.total}
                      readOnly
                    />
                  </td>
                  <td>
                    <IoIosRemoveCircle
                      style={{ fontSize: "1.5rem", color: "red" }}
                      onClick={() => removeItem(index)}
                      disabled={items.length === 1}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="9" className="text-end fw-bold">
                  Grand Total:
                </td>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Final Total"
                    value={Finaltotal}
                    readOnly
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Button variant="success" size="sm" onClick={addItem}>
            Add Row
          </Button>
          <h5 className="mt-2">Payment Details</h5>
          <Row className="p-0">
            <Col md={5}>
              <Form.Group className="mb-3">
                <Form.Label>Payment Mode</Form.Label>
                <Select
                  options={paymentOptions}
                  value={paymentOptions.find(
                    (option) => option.value === paymentMode
                  )}
                  onChange={(selectedOption) =>
                    setPaymentMode(selectedOption.value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          {paymentMode === "cheque" && (
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Sender Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Sender Name"
                    name="senderName"
                    value={formData.senderName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Receiver Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Receiver Name"
                    name="receiverName"
                    value={formData.receiverName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Bank Name"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Account Number"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Cheque Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Cheque Number"
                    name="chequeNumber"
                    value={formData.chequeNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Amount"
                    name="chequeAmount"
                    value={formData.chequeAmount || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Date of Cheque</Form.Label>
                  <Form.Control
                    type="date"
                    name="chequeDate"
                    value={formData.chequeDate}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Cheque Image</Form.Label>
                  <Form.Control type="file" onChange={handleChequeUpload} />
                </Form.Group>
              </Col>
              <Col md={6}>
                {formData.chequeImg && (
                  <div>
                    <img
                      src={formData.chequeImg}
                      alt="Cheque Preview"
                      className="img-thumbnail"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}

          {paymentMode === "cash" && (
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Amount"
                    name="chequeAmount"
                    value={formData.chequeAmount || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {["IMPS", "NEFT", "RTGS"].includes(paymentMode) && (
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>UTR</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter UTR"
                    name="UTR"
                    value={formData.UTR || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Amount"
                    name="transamount"
                    value={formData.transamount || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <Modal show={showAddItemModal} onHide={() => setShowAddItemModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Item Name"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>HSN Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Item Name"
                value={Hsn}
                onChange={(e) => setHsn(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Price"
                value={Price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tax</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tax"
                value={Tax}
                onChange={(e) => setTax(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddItemSubmit}>
            Add Item
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddVendorBill;
